import TYPE_DICTIONARY, { get_request } from 'api/Requests';

import {
  GET_ADMIN_STATISTIC_EDNPOINT,
  GET_ADMIN_STATISTIC_REDUX,
} from './AdminStatisticConstants';

const getAdminStatisticAction = get_request(
  GET_ADMIN_STATISTIC_REDUX,
  GET_ADMIN_STATISTIC_EDNPOINT,
  TYPE_DICTIONARY.token,
);

export default getAdminStatisticAction;
