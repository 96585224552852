import { createSlice } from '@reduxjs/toolkit';

import registerAction, { validateSendingAction } from './RegisterActions';

import { REGISTE_REDUX } from './RegisterConstants';

const initialState = {
  registerFetching: false,
  validateSendFetching: false,
};

const registerSlice = createSlice({
  name: REGISTE_REDUX,
  initialState,
  reducers: {
    setRegisterFetching: (state, payload) => {
      state.registerFetching = payload;
    },
  },
  extraReducers: {
    [registerAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.registerFetching = true;
    },
    [registerAction.fulfilled.type]: (state) => {
      state.registerFetching = false;
    },
    [registerAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.registerFetching = false;
    },
    [validateSendingAction.pending.type]: (state) => {
      state.validateSendFetching = true;
    },
    [validateSendingAction.fulfilled.type]: (state) => {
      state.validateSendFetching = false;
    },
    [validateSendingAction.rejected.type]: (state) => {
      state.validateSendFetching = false;
    },
  },
});

export const { setRegisterFetching } = registerSlice.actions;
export default registerSlice.reducer;
