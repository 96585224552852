const GET_PAYMENT_HISTORY_EDNPOINT = '/payment/history';

const PAYMENT_HISTORY_REDUX = 'userPaymentHistory';
const GET_PAYMENT_HISTORY_REDUX = 'userPaymentHistory/information';

export {
  GET_PAYMENT_HISTORY_EDNPOINT,
  PAYMENT_HISTORY_REDUX,
  GET_PAYMENT_HISTORY_REDUX,
};
