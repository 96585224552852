const getSearchQuery = (query) => {
  const indexOfSymbol = query.indexOf('=');
  const indexOfANDSymbol = query.indexOf('&');
  const newFirstString = query.slice(indexOfSymbol + 1, indexOfANDSymbol);

  return newFirstString;
};

const setDisableElement = (className, state) => {
  const submitButton = document.querySelector(className);
  submitButton.disabled = state;
};

const isEmptyState = (state) => {
  let flag = false;

  if (state && state.length === 0) {
    flag = true;
  }
  return flag;
};

const checkFlag = (newState, field) => {
  let flag = false;
  newState.forEach((state) => {
    if (state[field]) flag = true;
  });

  return flag;
};

const clearLocaleStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('role');
  localStorage.removeItem('user');
  localStorage.removeItem('email');
  localStorage.removeItem('paid_status');
};

export {
  getSearchQuery,
  clearLocaleStorage,
  isEmptyState,
  setDisableElement,
  checkFlag,
};
