import { lazy } from 'react';

import RedirectPage from 'pages/RedirectPage/RedirectPage';

import {
  ADMIN_HOME_PATH,
  ADMIN_SETTINGS_PATH,
  ADMIN_STATISTICS_PATH,
  CHECK_EMAIL_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  NEW_PASSWORD_PATH,
  PAYMENT_PATH,
  REDIRECT_ROLE_PATH,
  REGISTER_PATH,
  START_PATH,
  USER_HOME_PATH,
  USER_PAYMENT_HISTORY_PATH,
  USER_SETTINGS_PATH,
} from './path';
import RoleRedirectPage from 'pages/RoleRedirectPage/RoleRedirectPage';
import PrivateGuard from 'components/PrivateGuard/PrivateGuard';
import { ROLE_DICTIONARY } from 'core/Constants';

/* Load component */

const LazyLoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const LazyForgotPasswordPage = lazy(() =>
  import('pages/ForgotPasswordPage/ForgotPasswordPage'),
);
const LazyNewPasswordPage = lazy(() =>
  import('pages/NewPasswordPage/NewPasswordPage'),
);
const LazyRegisterPage = lazy(() => import('pages/RegisterPage/RegisterPage'));
const LazyCheckEmailPage = lazy(() =>
  import('pages/CheckEmailPage/CheckEmailPage'),
);

const LazyPaymentPage = lazy(() => import('pages/PaymentPage/PaymentPage'));

const LazyStartPage = lazy(() => import('pages/StartPage/StartPage'));

const LazyUserPage = lazy(() => import('pages/UserPage/UserPage'));
const LazyUserSettingsPage = lazy(() =>
  import('pages/UserSettingsPage/UserSettingsPage'),
);
const LazyUserPaymentHistoryPage = lazy(() =>
  import('pages/UserPaymentHistory/UserPaymentHistory'),
);

const LazyAdminPage = lazy(() => import('pages/AdminPage/AdminPage'));
const LazyAdminSettingsPage = lazy(() =>
  import('pages/AdminSettingsPage/AdminSettingsPage'),
);
const LazyAdminStatisticsPage = lazy(() =>
  import('pages/AdminStatisticsPage/AdminStatisticsPage'),
);

const routes = [
  { path: '/', element: <RedirectPage /> },
  {
    path: LOGIN_PATH,
    element: <LazyLoginPage />,
  },
  { path: PAYMENT_PATH, element: <LazyPaymentPage /> },
  {
    path: FORGOT_PASSWORD_PATH,
    element: <LazyForgotPasswordPage />,
  },
  { path: CHECK_EMAIL_PATH, element: <LazyCheckEmailPage /> },
  {
    path: NEW_PASSWORD_PATH,
    element: <LazyNewPasswordPage />,
  },
  {
    path: REGISTER_PATH,
    element: <LazyRegisterPage />,
  },
  { path: START_PATH, element: <LazyStartPage /> },
  { path: REDIRECT_ROLE_PATH, element: <RoleRedirectPage /> },
  {
    path: USER_HOME_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.user}>
        <LazyUserPage />
      </PrivateGuard>
    ),
  },
  {
    path: USER_SETTINGS_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.user}>
        <LazyUserSettingsPage />
      </PrivateGuard>
    ),
  },
  {
    path: USER_PAYMENT_HISTORY_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.user}>
        <LazyUserPaymentHistoryPage />
      </PrivateGuard>
    ),
  },
  {
    path: ADMIN_HOME_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.admin}>
        <LazyAdminPage />
      </PrivateGuard>
    ),
  },
  {
    path: ADMIN_SETTINGS_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.admin}>
        <LazyAdminSettingsPage />
      </PrivateGuard>
    ),
  },
  {
    path: ADMIN_STATISTICS_PATH,
    element: (
      <PrivateGuard roleSystem={ROLE_DICTIONARY.admin}>
        <LazyAdminStatisticsPage />
      </PrivateGuard>
    ),
  },
];

export default routes;
