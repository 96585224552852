import { createSlice } from '@reduxjs/toolkit';

import { ADMIN_SETTINGS_REDUX } from './AdminSettingsConstants';
import adminChangePasswordAction, {
  adminChangeSubscriptionAction,
  getGPTFileInfoAction,
  trainGPTAction,
} from './AdminSettingsActions';

const initialState = {
  adminChangePasswordFetching: false,
  adminChangeSubscriptionFetching: false,
  gptModelInfoFetching: false,
  trainGPTFetching: false,
};

const adminSettingsSlice = createSlice({
  name: ADMIN_SETTINGS_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [adminChangePasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.adminChangePasswordFetching = true;
    },
    [adminChangePasswordAction.fulfilled.type]: (state) => {
      state.adminChangePasswordFetching = false;
    },
    [adminChangePasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.adminChangePasswordFetching = false;
    },

    [adminChangeSubscriptionAction.pending.type]: (state) => {
      state.adminChangeSubscriptionFetching = true;
    },
    [adminChangeSubscriptionAction.fulfilled.type]: (state) => {
      state.adminChangeSubscriptionFetching = false;
    },
    [adminChangeSubscriptionAction.rejected.type]: (state) => {
      state.adminChangeSubscriptionFetching = false;
    },

    [getGPTFileInfoAction.pending.type]: (state) => {
      state.gptModelInfoFetching = true;
    },
    [getGPTFileInfoAction.fulfilled.type]: (state) => {
      state.gptModelInfoFetching = false;
    },
    [getGPTFileInfoAction.rejected.type]: (state) => {
      state.gptModelInfoFetching = false;
    },

    [trainGPTAction.pending.type]: (state) => {
      state.trainGPTFetching = true;
    },
    [trainGPTAction.fulfilled.type]: (state) => {
      state.trainGPTFetching = false;
    },
    [trainGPTAction.rejected.type]: (state) => {
      state.trainGPTFetching = false;
    },
  },
});

export default adminSettingsSlice.reducer;
