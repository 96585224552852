const FORGOT_PASSWORD_ENDPOINT = '/users/password/restore/send';

const FORGOT_PASSWORD_REDUX = 'forgotPassword';
const FORGOT_PASSWORD_REQUEST_REDUX = 'forgotPassword/post';

export {
  FORGOT_PASSWORD_ENDPOINT,
  FORGOT_PASSWORD_REDUX,
  FORGOT_PASSWORD_REQUEST_REDUX,
};
