import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';

import {
  GET_ALL_USERS_ENDPOINT,
  GET_ALL_USERS_REDUX,
  GET_CHAT_MESSAGES_ENDPOINT,
  GET_CHAT_MESSAGES_REDUX,
  GET_USERS_ENDPOINT,
  GET_USERS_REDUX,
  GET_USER_QUESTION_ENDPOINT,
  GET_USER_QUESTION_REDUX,
  SEND_ANSWER_ENDPOINT,
  SEND_ANSWER_REDUX,
} from './AdminPageConstants';

const getUsersAction = get_request(
  GET_USERS_REDUX,
  GET_USERS_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getAllUsersAction = get_request(
  GET_ALL_USERS_REDUX,
  GET_ALL_USERS_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getAdminMessagesAction = get_request(
  GET_CHAT_MESSAGES_REDUX,
  GET_CHAT_MESSAGES_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getAdminQuestionAction = get_request(
  GET_USER_QUESTION_REDUX,
  GET_USER_QUESTION_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const adminSendAnswerAction = post_request(
  SEND_ANSWER_REDUX,
  SEND_ANSWER_ENDPOINT,
  TYPE_DICTIONARY.token,
);

export {
  getAllUsersAction,
  getAdminMessagesAction,
  getAdminQuestionAction,
  adminSendAnswerAction,
};
export default getUsersAction;
