import TYPE_DICTIONARY, {
  delete_request,
  get_request,
  post_request,
} from 'api/Requests';

import {
  CREATE_CHAT_ENDPOINT,
  CREATE_CHAT_REDUX,
  DELETE_CHAT_ENDPOINT,
  DELETE_CHAT_REDUX,
  GET_CHATS_ENDPOINT,
  GET_CHATS_REDUX,
  GET_MESSAGES_ENDPOINT,
  GET_MESSAGES_REDUX,
  SEND_MESSAGE_ENDPOINT,
  SEND_MESSAGE_REDUX,
  USER_ASK_HELP_ENDPOINT,
  USER_ASK_HELP_REDUX,
} from './UserPageConstants';

const getUserChatsAction = get_request(
  GET_CHATS_REDUX,
  GET_CHATS_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const createUserChatAction = post_request(
  CREATE_CHAT_REDUX,
  CREATE_CHAT_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getMessagesAction = get_request(
  GET_MESSAGES_REDUX,
  GET_MESSAGES_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const sendMessageAction = post_request(
  SEND_MESSAGE_REDUX,
  SEND_MESSAGE_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const deleteUserChatAction = delete_request(
  DELETE_CHAT_REDUX,
  DELETE_CHAT_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const userAskToHelpAction = post_request(
  USER_ASK_HELP_REDUX,
  USER_ASK_HELP_ENDPOINT,
  TYPE_DICTIONARY.token,
);

export {
  userAskToHelpAction,
  createUserChatAction,
  getMessagesAction,
  sendMessageAction,
  deleteUserChatAction,
};
export default getUserChatsAction;
