const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',

  get apiHeaders() {
    return {
      Accept: this.Accept,
      'Content-Type': this['Content-Type'],
    };
  },

  set apiAccept(value) {
    this.Accept = value;
  },

  set apiContentType(value) {
    this['Content-Type'] = value;
  },
};

export default headers;
