const GET_SUBSCRIPTION_ENDPOINT = '/subscriptions';
const EMAIL_SUBSCRIBE_ENDPOINT = '/information/email';

const START_PAGE_REDUX = 'startPage';
const START_PAGE_SUBSCRIPTION = 'startPage/subscription';
const START_PAGE_EMAIL_SUBSCRIBE = 'startPage/email';

export {
  GET_SUBSCRIPTION_ENDPOINT,
  EMAIL_SUBSCRIBE_ENDPOINT,
  START_PAGE_REDUX,
  START_PAGE_SUBSCRIPTION,
  START_PAGE_EMAIL_SUBSCRIBE,
};
