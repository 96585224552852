import TYPE_DICTIONARY, { get_request } from 'api/Requests';

import {
  GET_PAYMENT_HISTORY_EDNPOINT,
  GET_PAYMENT_HISTORY_REDUX,
} from './UserPaymentHistoryConstants';

const getUserPaymentHistoryAction = get_request(
  GET_PAYMENT_HISTORY_REDUX,
  GET_PAYMENT_HISTORY_EDNPOINT,
  TYPE_DICTIONARY.token,
);

export default getUserPaymentHistoryAction;
