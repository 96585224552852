import TYPE_DICTIONARY, { post_request } from 'api/Requests';

import {
  FORGOT_PASSWORD_ENDPOINT,
  FORGOT_PASSWORD_REQUEST_REDUX,
} from './ForgotPasswordConstants';

const forgotPasswordAction = post_request(
  FORGOT_PASSWORD_REQUEST_REDUX,
  FORGOT_PASSWORD_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

export default forgotPasswordAction;
