import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router';
import React, { Suspense } from 'react';

import ScreenLoader from 'UI/ScreenLoader/ScreenLoader';

import ModeProvider from 'context/ModeContext';

import routes from 'router/router';

const toasterOptions = {
  style: {
    fontWeight: 500,
    fontFamily: "'Montserrat', sans-serif",
    maxWidth: '500px',
  },
};

const App = () => {
  const pages = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <ModeProvider>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        <Suspense fallback={<ScreenLoader />}>{pages}</Suspense>
      </ModeProvider>
    </StyledEngineProvider>
  );
};

export default App;
