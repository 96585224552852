import { Navigate } from 'react-router';
import React from 'react';

import { START_PATH } from 'router/path';

const PrivateGuard = ({ children, roleSystem }) => {
  const role = localStorage.getItem('role');
  const accessToken = localStorage.getItem('accessToken');

  if (!role || roleSystem !== role || !accessToken)
    return <Navigate to={START_PATH} replace />;

  return <>{children}</>;
};

export default PrivateGuard;
