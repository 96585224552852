const GET_USERS_ENDPOINT = '/users/admin/list/open';
const GET_ALL_USERS_ENDPOINT = '/users/admin/list';
const GET_CHAT_MESSAGES_ENDPOINT = '/chats/admin/messages';
const GET_USER_QUESTION_ENDPOINT = '/chats/admin/messages/opened';
const SEND_ANSWER_ENDPOINT = '/chats/admin/messages';

const ADMIN_REDUX = 'admin';
const GET_USERS_REDUX = 'admin/getUsers';
const GET_ALL_USERS_REDUX = 'admin/getAllUsers';
const GET_CHAT_MESSAGES_REDUX = 'admin/chatMessages';
const GET_USER_QUESTION_REDUX = 'admin/questionMessages';
const SEND_ANSWER_REDUX = 'admin/sendAnswer';

export {
  GET_USERS_ENDPOINT,
  ADMIN_REDUX,
  GET_CHAT_MESSAGES_ENDPOINT,
  GET_ALL_USERS_ENDPOINT,
  GET_USER_QUESTION_ENDPOINT,
  SEND_ANSWER_ENDPOINT,
  GET_USERS_REDUX,
  GET_ALL_USERS_REDUX,
  GET_CHAT_MESSAGES_REDUX,
  GET_USER_QUESTION_REDUX,
  SEND_ANSWER_REDUX,
};
