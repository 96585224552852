const ROLE_DICTIONARY = {
  user: 'USER',
  admin: 'ADMIN',
};

const PAYMENT_DICTIONARY = {
  paypal: 'PayPal Checkout',
  stripe: 'Stripe',
};

const ICON_SCALE_STYLES = {
  cursor: 'pointer',
  transition: 'all .3s',
  ':hover': {
    transform: 'scale(1.1)',
  },
};

const PAID_STATUS_DICTIONARY = {
  paid: 'PAID',
  free: 'FREE',
};

const REQUEST_SIZE = 100;

const FETCHER_SIZE = 10;

export {
  REQUEST_SIZE,
  FETCHER_SIZE,
  ROLE_DICTIONARY,
  PAYMENT_DICTIONARY,
  ICON_SCALE_STYLES,
  PAID_STATUS_DICTIONARY,
};
