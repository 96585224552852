const RESET_PASSWORD_ENDPOINT = '/users/password/restore/confirm';

const RESET_PASSWORD_REDUX = 'resetPassword';
const RESET_PASSWORD_REQUEST_REDUX = 'resetPassword/post';

export {
  RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_REDUX,
  RESET_PASSWORD_REQUEST_REDUX,
};
