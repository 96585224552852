import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';

import {
  EMAIL_SUBSCRIBE_ENDPOINT,
  GET_SUBSCRIPTION_ENDPOINT,
  START_PAGE_EMAIL_SUBSCRIBE,
  START_PAGE_SUBSCRIPTION,
} from './StartPageConstants';

const getSubscriptionAction = get_request(
  START_PAGE_SUBSCRIPTION,
  GET_SUBSCRIPTION_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

const emailSubscribeAction = post_request(
  START_PAGE_EMAIL_SUBSCRIBE,
  EMAIL_SUBSCRIBE_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

export { emailSubscribeAction };
export default getSubscriptionAction;
