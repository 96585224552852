import { createSlice } from '@reduxjs/toolkit';

import forgotPasswordAction from './ForgotPasswordActions';

import { FORGOT_PASSWORD_REDUX } from './ForgotPasswordConstants';

const initialState = {
  forgotPasswordFetching: false,
};

const forgotPasswordSlice = createSlice({
  name: FORGOT_PASSWORD_REDUX,
  initialState,
  reducers: {
    setForgotPasswordFetching: (state, payload) => {
      state.forgotPasswordFetching = payload;
    },
  },
  extraReducers: {
    [forgotPasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.forgotPasswordFetching = true;
    },
    [forgotPasswordAction.fulfilled.type]: (state) => {
      state.forgotPasswordFetching = false;
    },
    [forgotPasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.forgotPasswordFetching = false;
    },
  },
});

export const { setForgotPasswordFetching } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
