const REGISTER_ENDPOINT = '/registration';
const VALIDATE_SEND_ENDPOINT = '/users/email/validate/send';

const REGISTE_REDUX = 'register';
const REGISTE_REQUEST_REDUX = 'register/post';
const VALIDATE_SEND_REDUX = 'register/validateSend';

export {
  REGISTER_ENDPOINT,
  VALIDATE_SEND_ENDPOINT,
  REGISTE_REDUX,
  REGISTE_REQUEST_REDUX,
  VALIDATE_SEND_REDUX,
};
