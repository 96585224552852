import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';
import {
  GET_SUBSCRIBE_PLANS_ENDPOINT,
  GET_SUBSCRIBE_PLANS_REDUX,
  PAYMENT_STRIPE_SUBSCRIPTION,
  PAYPAL_SUBSCRIBE_ENDPOINT,
  PAYPAL_SUBSCRIBE_REDUX,
  STRIPE_SUBSCRIBE_ENDPOINT,
} from './PaymentConstants';

const stripePaymentAction = post_request(
  PAYMENT_STRIPE_SUBSCRIPTION,
  STRIPE_SUBSCRIBE_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const paypalSubscribeAction = post_request(
  PAYPAL_SUBSCRIBE_REDUX,
  PAYPAL_SUBSCRIBE_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getSubcriptionsAction = get_request(
  GET_SUBSCRIBE_PLANS_REDUX,
  GET_SUBSCRIBE_PLANS_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

export { paypalSubscribeAction, getSubcriptionsAction };
export default stripePaymentAction;
