import { createSlice } from '@reduxjs/toolkit';

import { USER_SETTINGS_REDUX } from './UserSettingsConstants';
import userChangePasswordAction, {
  getUserPaymentInfoAction,
  userChangePaymentAction,
} from './UserSettingsActions';

const initialState = {
  userChangePasswordFetching: false,
  changePaymentFetching: false,
  paymentInfoFetching: false,
};

const userSettingsSlice = createSlice({
  name: USER_SETTINGS_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [userChangePasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.userChangePasswordFetching = true;
    },
    [userChangePasswordAction.fulfilled.type]: (state) => {
      state.userChangePasswordFetching = false;
    },
    [userChangePasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.userChangePasswordFetching = false;
    },

    [userChangePaymentAction.pending.type]: (state) => {
      state.changePaymentFetching = true;
    },
    [userChangePaymentAction.fulfilled.type]: (state) => {
      state.changePaymentFetching = false;
    },
    [userChangePaymentAction.rejected.type]: (state) => {
      state.changePaymentFetching = false;
    },

    [getUserPaymentInfoAction.pending.type]: (state) => {
      state.paymentInfoFetching = true;
    },
    [getUserPaymentInfoAction.fulfilled.type]: (state) => {
      state.paymentInfoFetching = false;
    },
    [getUserPaymentInfoAction.rejected.type]: (state) => {
      state.paymentInfoFetching = false;
    },
  },
});

export default userSettingsSlice.reducer;
