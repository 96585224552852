import { createSlice } from '@reduxjs/toolkit';

import { LOGIN_REDUX } from './LoginConstants';
import loginAction, { getUserAction } from './LoginActions';

const initialState = {
  loginFetching: false,
  userFetching: false,
};

const loginSlice = createSlice({
  name: LOGIN_REDUX,
  initialState,
  reducers: {
    setLoginFetching: (state, payload) => {
      state.loginFetching = payload;
    },
  },
  extraReducers: {
    [loginAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.loginFetching = true;
    },
    [loginAction.fulfilled.type]: (state) => {
      state.loginFetching = false;
    },
    [loginAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.loginFetching = false;
    },
    [getUserAction.pending.type]: (state) => {
      state.userFetching = true;
    },
    [getUserAction.fulfilled.type]: (state) => {
      state.userFetching = false;
    },
    [getUserAction.rejected.type]: (state) => {
      state.userFetching = false;
    },
  },
});

export const { setLoginFetching } = loginSlice.actions;
export default loginSlice.reducer;
