const LOGIN_PATH = '/auth/login';
const FORGOT_PASSWORD_PATH = '/auth/forgot-password';
const NEW_PASSWORD_PATH = '/password/restore';
const REGISTER_PATH = '/auth/register';
const CHECK_EMAIL_PATH = '/auth/check-email';

const PAYMENT_PATH = '/payment';

const START_PATH = '/home';
const REDIRECT_ROLE_PATH = '/redirect';

const USER_HOME_PATH = '/chat';
const USER_SETTINGS_PATH = '/user/settings';
const USER_PAYMENT_HISTORY_PATH = '/user/payment-history';

const ADMIN_HOME_PATH = '/admin';
const ADMIN_SETTINGS_PATH = '/admin/settings';
const ADMIN_STATISTICS_PATH = '/admin/statistics';

export {
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  NEW_PASSWORD_PATH,
  CHECK_EMAIL_PATH,
  PAYMENT_PATH,
  REGISTER_PATH,
  START_PATH,
  REDIRECT_ROLE_PATH,
  USER_HOME_PATH,
  USER_SETTINGS_PATH,
  USER_PAYMENT_HISTORY_PATH,
  ADMIN_HOME_PATH,
  ADMIN_SETTINGS_PATH,
  ADMIN_STATISTICS_PATH,
};
