import TYPE_DICTIONARY, { post_request } from 'api/Requests';

import {
  REGISTER_ENDPOINT,
  REGISTE_REQUEST_REDUX,
  VALIDATE_SEND_ENDPOINT,
  VALIDATE_SEND_REDUX,
} from './RegisterConstants';

const registerAction = post_request(
  REGISTE_REQUEST_REDUX,
  REGISTER_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

const validateSendingAction = post_request(
  VALIDATE_SEND_REDUX,
  VALIDATE_SEND_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

export { validateSendingAction };
export default registerAction;
