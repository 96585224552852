const STRIPE_SUBSCRIBE_ENDPOINT = '/payment/stripe/create/user-subscription';
const PAYPAL_SUBSCRIBE_ENDPOINT = '/payment/paypal/webhook/local';
const GET_SUBSCRIBE_PLANS_ENDPOINT = '/subscriptions/general/list';

const PAYMENT_REDUX = 'payment';
const PAYMENT_STRIPE_SUBSCRIPTION = 'payment/stripe';
const PAYPAL_SUBSCRIBE_REDUX = 'payment/paypal';
const GET_SUBSCRIBE_PLANS_REDUX = 'payment/subscribePlans';

export {
  STRIPE_SUBSCRIBE_ENDPOINT,
  PAYPAL_SUBSCRIBE_ENDPOINT,
  GET_SUBSCRIBE_PLANS_ENDPOINT,
  PAYMENT_REDUX,
  PAYMENT_STRIPE_SUBSCRIPTION,
  PAYPAL_SUBSCRIBE_REDUX,
  GET_SUBSCRIBE_PLANS_REDUX,
};
