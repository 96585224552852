import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';

import store from 'store/configureStore';
import App from './App';

import './styles/normalize.css';
import 'react-credit-cards/es/styles-compiled.css';
import './styles/index.css';
import './styles/App.css';
import 'nprogress/nprogress.css';
import 'simplebar/dist/simplebar.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
