const GET_CHATS_ENDPOINT = '/chats';
const CREATE_CHAT_ENDPOINT = '/chats';
const GET_MESSAGES_ENDPOINT = '/chats/messages';
const SEND_MESSAGE_ENDPOINT = '/chats/messages';
const DELETE_CHAT_ENDPOINT = '/chats';
const USER_ASK_HELP_ENDPOINT = '/chats/messages/ask-help';

const USER_REDUX = 'user';
const GET_CHATS_REDUX = 'user/getChats';
const CREATE_CHAT_REDUX = 'user/creatChat';
const GET_MESSAGES_REDUX = 'user/getMessages';
const SEND_MESSAGE_REDUX = 'user/sendMessage';
const DELETE_CHAT_REDUX = 'user/deleteChat';
const USER_ASK_HELP_REDUX = 'user/askHelp';

export {
  GET_CHATS_ENDPOINT,
  USER_ASK_HELP_ENDPOINT,
  CREATE_CHAT_ENDPOINT,
  GET_MESSAGES_ENDPOINT,
  SEND_MESSAGE_ENDPOINT,
  DELETE_CHAT_ENDPOINT,
  GET_CHATS_REDUX,
  USER_REDUX,
  CREATE_CHAT_REDUX,
  GET_MESSAGES_REDUX,
  SEND_MESSAGE_REDUX,
  USER_ASK_HELP_REDUX,
  DELETE_CHAT_REDUX,
};
