import { createSlice } from '@reduxjs/toolkit';

import { PAYMENT_REDUX } from './PaymentConstants';
import stripePaymentAction, {
  getSubcriptionsAction,
  paypalSubscribeAction,
} from './PaymentActions';

const initialState = {
  stripeSubscribeFetching: false,
  paypalSubscribeFetching: false,
  subscriptionsFetching: false,
};

const paymentSlice = createSlice({
  name: PAYMENT_REDUX,
  initialState,
  reducers: {
    setStripeSubscribeFetching: (state, payload) => {
      state.stripeSubscribeFetching = payload;
    },
  },
  extraReducers: {
    [stripePaymentAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.stripeSubscribeFetching = true;
    },
    [stripePaymentAction.fulfilled.type]: (state) => {
      state.stripeSubscribeFetching = false;
    },
    [stripePaymentAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.stripeSubscribeFetching = false;
    },

    [paypalSubscribeAction.pending.type]: (state) => {
      state.paypalSubscribeFetching = true;
    },
    [paypalSubscribeAction.fulfilled.type]: (state) => {
      state.paypalSubscribeFetching = false;
    },
    [paypalSubscribeAction.rejected.type]: (state) => {
      state.paypalSubscribeFetching = false;
    },

    [getSubcriptionsAction.pending.type]: (state) => {
      state.subscriptionsFetching = true;
    },
    [getSubcriptionsAction.fulfilled.type]: (state) => {
      state.subscriptionsFetching = false;
    },
    [getSubcriptionsAction.rejected.type]: (state) => {
      state.subscriptionsFetching = false;
    },
  },
});

export const { setStripeSubscribeFetching } = paymentSlice.actions;
export default paymentSlice.reducer;
