import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';
import {
  ADMIN_CHANGE_PASSWORD_ENDPOINT,
  ADMIN_CHANGE_PASSWORD_REDUX,
  ADMIN_CHANGE_SUBSCRIBTION_ENDPOINT,
  ADMIN_CHANGE_SUBSCRIBTION_REDUX,
  CHANGE_EMAIL_ENDPOINT,
  CHANGE_EMAIL_REDUX,
  GET_EMAIL_ENDPOINT,
  GET_EMAIL_REDUX,
  GET_GPT_INFORMATION_ENDPOINT,
  GET_GPT_INFORMATION_REDUX,
  TRAIN_GPT_ENDPOINT,
  TRAIN_GPT_REDUX,
} from './AdminSettingsConstants';

const adminChangePasswordAction = post_request(
  ADMIN_CHANGE_PASSWORD_REDUX,
  ADMIN_CHANGE_PASSWORD_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getGPTFileInfoAction = get_request(
  GET_GPT_INFORMATION_REDUX,
  GET_GPT_INFORMATION_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const trainGPTAction = post_request(
  TRAIN_GPT_REDUX,
  TRAIN_GPT_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const adminChangeSubscriptionAction = post_request(
  ADMIN_CHANGE_SUBSCRIBTION_REDUX,
  ADMIN_CHANGE_SUBSCRIBTION_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getAdminEmailAction = get_request(
  GET_EMAIL_REDUX,
  GET_EMAIL_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const changeAdminEmailActions = post_request(
  CHANGE_EMAIL_REDUX,
  CHANGE_EMAIL_ENDPOINT,
  TYPE_DICTIONARY.token,
);

export {
  getGPTFileInfoAction,
  trainGPTAction,
  getAdminEmailAction,
  adminChangeSubscriptionAction,
  changeAdminEmailActions,
};
export default adminChangePasswordAction;
