import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import registerSlice from 'pages/RegisterPage/service/RegisterSlice';
import loginSlice from 'pages/LoginPage/service/LoginSlice';
import forgotPasswordSlice from 'pages/ForgotPasswordPage/service/ForgotPasswordSlice';
import resetPasswordSlice from 'pages/NewPasswordPage/service/ResetPasswordSlice';

import paymentSlice from 'pages/PaymentPage/services/PaymentSlice';

import startPageSlice from 'pages/StartPage/services/StartPageSlice';

import userPageSlice from 'pages/UserPage/services/UserPageSlice';
import userSettingsSlice from 'pages/UserSettingsPage/services/UserSettingsSlice';
import userPaymentHistorySlice from 'pages/UserPaymentHistory/service/UserPaymentHistorySlice';

import adminPageSlice from 'pages/AdminPage/services/AdminPageSlice';
import adminSettingsSlice from 'pages/AdminSettingsPage/services/AdminSettingsSlice';
import adminStatisticSlice from 'pages/AdminStatisticsPage/service/AdminStatisticSlice';

const reducers = {
  registerReducer: registerSlice,
  loginReducer: loginSlice,
  forgotPasswordReducer: forgotPasswordSlice,
  newPasswordReducer: resetPasswordSlice,
  paymentReducer: paymentSlice,
  startPageReducer: startPageSlice,
  userReducer: userPageSlice,
  userSettingsReducer: userSettingsSlice,
  userPaymentHistoryReducer: userPaymentHistorySlice,
  adminReducer: adminPageSlice,
  adminSettingsReducer: adminSettingsSlice,
  adminStatisticReducer: adminStatisticSlice,
};

export default configureStore({
  reducer: reducers,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
