import { createSlice } from '@reduxjs/toolkit';

import resetPasswordAction from './ResetPasswordActions';

import { RESET_PASSWORD_REDUX } from './ResetPasswordConstants';

const initialState = {
  resetPasswordFetching: false,
};

const resetPasswordSlice = createSlice({
  name: RESET_PASSWORD_REDUX,
  initialState,
  reducers: {
    setResetPasswordFetching: (state, payload) => {
      state.resetPasswordFetching = payload;
    },
  },
  extraReducers: {
    [resetPasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.resetPasswordFetching = true;
    },
    [resetPasswordAction.fulfilled.type]: (state) => {
      state.resetPasswordFetching = false;
    },
    [resetPasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.resetPasswordFetching = false;
    },
  },
});

export const { setResetPasswordFetching } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
