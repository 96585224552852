import { createSlice } from '@reduxjs/toolkit';

import { getUserAction } from 'pages/LoginPage/service/LoginActions';
import { USER_REDUX } from './UserPageConstants';
import {
  createUserChatAction,
  deleteUserChatAction,
  getMessagesAction,
  sendMessageAction,
} from './UserPageActions';

const initialState = {
  chatsFetching: false,
  createChatFetching: false,
  messagesFetching: false,
  sendMessageFetching: false,
  deleteChatFetching: false,
};

const userPageSlice = createSlice({
  name: USER_REDUX,
  initialState,
  reducers: {
    setChatsFetching: (state, payload) => {
      state.chatsFetching = payload;
    },
    setCreateChatFetching: (state, payload) => {
      state.createChatFetching = payload;
    },
  },
  extraReducers: {
    [getUserAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.chatsFetching = true;
    },
    [getUserAction.fulfilled.type]: (state) => {
      state.chatsFetching = false;
    },
    [getUserAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.chatsFetching = false;
    },

    [createUserChatAction.pending.type]: (state) => {
      state.createChatFetching = true;
    },
    [createUserChatAction.fulfilled.type]: (state) => {
      state.createChatFetching = false;
    },
    [createUserChatAction.rejected.type]: (state) => {
      state.createChatFetching = false;
    },

    [getMessagesAction.pending.type]: (state) => {
      state.messagesFetching = true;
    },
    [getMessagesAction.fulfilled.type]: (state) => {
      state.messagesFetching = false;
    },
    [getMessagesAction.rejected.type]: (state) => {
      state.messagesFetching = false;
    },

    [sendMessageAction.pending.type]: (state) => {
      state.sendMessageFetching = true;
    },
    [sendMessageAction.fulfilled.type]: (state) => {
      state.sendMessageFetching = false;
    },
    [sendMessageAction.rejected.type]: (state) => {
      state.sendMessageFetching = false;
    },

    [deleteUserChatAction.pending.type]: (state) => {
      state.deleteChatFetching = true;
    },
    [deleteUserChatAction.fulfilled.type]: (state) => {
      state.deleteChatFetching = false;
    },
    [deleteUserChatAction.rejected.type]: (state) => {
      state.deleteChatFetching = false;
    },
  },
});

export const { setChatsFetching, setCreateChatFetching } =
  userPageSlice.actions;
export default userPageSlice.reducer;
