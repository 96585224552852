const ADMIN_CHANGE_PASSWORD_ENDPOINT = '/users/password/change';
const ADMIN_CHANGE_SUBSCRIBTION_ENDPOINT = '/subscriptions/general/update';
const GET_GPT_INFORMATION_ENDPOINT = '/gpt/model';
const TRAIN_GPT_ENDPOINT = '/gpt/training/files';
const GET_EMAIL_ENDPOINT = '/app/setting/adminNotificationEmail'
const CHANGE_EMAIL_ENDPOINT = '/app/setting'
// const TRAIN_GPT_ENDPOINT = '/gpt/training/files/new';

const ADMIN_SETTINGS_REDUX = 'adminSettings';
const ADMIN_CHANGE_PASSWORD_REDUX = 'adminSettings/changePassword';
const ADMIN_CHANGE_SUBSCRIBTION_REDUX = 'adminSettings/changeSubscription';
const GET_GPT_INFORMATION_REDUX = 'adminSettings/getGPTInfo';
const TRAIN_GPT_REDUX = 'adminSettings/trainGPT';
const GET_EMAIL_REDUX = 'adminSettings/email'
const CHANGE_EMAIL_REDUX = 'adminSettings/changeEmail'

export {
  ADMIN_CHANGE_PASSWORD_ENDPOINT,
  GET_GPT_INFORMATION_ENDPOINT,
  ADMIN_CHANGE_SUBSCRIBTION_ENDPOINT,
  TRAIN_GPT_ENDPOINT,
  ADMIN_SETTINGS_REDUX,
  ADMIN_CHANGE_SUBSCRIBTION_REDUX,
  ADMIN_CHANGE_PASSWORD_REDUX,
  GET_GPT_INFORMATION_REDUX,
  TRAIN_GPT_REDUX,
  GET_EMAIL_ENDPOINT,
  GET_EMAIL_REDUX,
  CHANGE_EMAIL_ENDPOINT,
  CHANGE_EMAIL_REDUX,
};
