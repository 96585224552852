import TYPE_DICTIONARY, { post_request } from 'api/Requests';

import {
  RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_REQUEST_REDUX,
} from './ResetPasswordConstants';

const resetPasswordAction = post_request(
  RESET_PASSWORD_REQUEST_REDUX,
  RESET_PASSWORD_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

export default resetPasswordAction;
