import { createSlice } from '@reduxjs/toolkit';

import { ADMIN_STATISTIC_REDUX } from './AdminStatisticConstants';
import getAdminStatisticAction from './AdminStatisticActions';

const initialState = {
  adminStatisticFetching: false,
};

const adminStatisticSlice = createSlice({
  name: ADMIN_STATISTIC_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [getAdminStatisticAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.adminStatisticFetching = true;
    },
    [getAdminStatisticAction.fulfilled.type]: (state) => {
      state.adminStatisticFetching = false;
    },
    [getAdminStatisticAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.adminStatisticFetching = false;
    },
  },
});

export default adminStatisticSlice.reducer;
