import { createSlice } from '@reduxjs/toolkit';

import { PAYMENT_HISTORY_REDUX } from './UserPaymentHistoryConstants';
import getUserPaymentHistoryAction from './UserPaymentHistoryActions';

const initialState = {
  paymentHistoryFetching: false,
};

const userPaymentHistorySlice = createSlice({
  name: PAYMENT_HISTORY_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [getUserPaymentHistoryAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.paymentHistoryFetching = true;
    },
    [getUserPaymentHistoryAction.fulfilled.type]: (state) => {
      state.paymentHistoryFetching = false;
    },
    [getUserPaymentHistoryAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.paymentHistoryFetching = false;
    },
  },
});

export default userPaymentHistorySlice.reducer;
