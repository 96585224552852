import { createAsyncThunk } from '@reduxjs/toolkit';

import { pureInstance, tokenInstance } from './Instance.js';

const TYPE_DICTIONARY = {
  pure: 'PURE',
  token: 'TOKEN',
};

const get_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let url = endpoint;
      const { params, id } = state;

      if (id) {
        url = `${endpoint}/${id}`;
      }

      if (type === TYPE_DICTIONARY.pure) {
        response = await pureInstance.get(url, {
          params,
        });
      } else {
        response = await tokenInstance.get(url, {
          params,
        });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const post_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let url = endpoint;
      const { params, id, payload } = state;

      if (id) {
        url = `${endpoint}/${id}`;
      }

      if (type === TYPE_DICTIONARY.pure) {
        response = await pureInstance.post(url, payload, {
          params,
        });
      } else {
        response = await tokenInstance.post(url, payload, {
          params,
        });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const put_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let url = endpoint;
      const { params, id, payload } = state;

      if (id) {
        url = `${endpoint}'/${id}`;
      }

      if (type === TYPE_DICTIONARY.pure) {
        response = await pureInstance.post(url, payload, {
          params,
        });
      } else {
        response = await tokenInstance.post(url, payload, {
          params,
        });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const delete_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let url = endpoint;
      const { params, id } = state;

      if (id) {
        url = `${endpoint}/${id}`;
      }

      if (type === TYPE_DICTIONARY.pure) {
        response = await pureInstance.delete(url, {
          params,
        });
      } else {
        response = await tokenInstance.delete(url, {
          params,
        });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

export { get_request, post_request, put_request, delete_request };
export default TYPE_DICTIONARY;
