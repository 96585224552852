import { createSlice } from '@reduxjs/toolkit';

import { START_PAGE_REDUX } from './StartPageConstants';
import getSubscriptionAction, {
  emailSubscribeAction,
} from './StartPageActions';

const initialState = {
  subscriptionFetching: false,
  emailSubscribeFetching: false,
};

const startPageSlice = createSlice({
  name: START_PAGE_REDUX,
  initialState,
  reducers: {
    setSubscriptionFetching: (state, payload) => {
      state.subscriptionFetching = payload;
    },
  },
  extraReducers: {
    [getSubscriptionAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.subscriptionFetching = true;
    },
    [getSubscriptionAction.fulfilled.type]: (state) => {
      state.subscriptionFetching = false;
    },
    [getSubscriptionAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.subscriptionFetching = false;
    },

    [emailSubscribeAction.pending.type]: (state) => {
      state.emailSubscribeFetching = true;
    },
    [emailSubscribeAction.fulfilled.type]: (state) => {
      state.emailSubscribeFetching = false;
    },
    [emailSubscribeAction.rejected.type]: (state) => {
      state.emailSubscribeFetching = false;
    },
  },
});

export const { setSubscriptionFetching } = startPageSlice.actions;
export default startPageSlice.reducer;
