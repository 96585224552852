import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';

import {
  GET_USER_ENDPOINT,
  GET_USER_REDUX,
  LOGIN_ENDPOINT,
  LOGIN_REQUEST_REDUX,
  STATUS_ENDPOINT,
  STATUS_REDUX,
} from './LoginConstants';

const loginAction = post_request(
  LOGIN_REQUEST_REDUX,
  LOGIN_ENDPOINT,
  TYPE_DICTIONARY.pure,
);

const getUserAction = get_request(
  GET_USER_REDUX,
  GET_USER_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getStatusAction = get_request(
  STATUS_REDUX,
  STATUS_ENDPOINT,
  TYPE_DICTIONARY.token,
);

export { getUserAction, getStatusAction };
export default loginAction;
