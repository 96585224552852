import { MODE } from 'context/ModeContext';

const primary = {
  main: '#eef2f6',
  dark: '#161c24',
};

const secondary = {
  main: '#009e4f',
  error: '#d32f2f',
};

const white = {
  100: '#ffffff',
};

const dark = {
  100: '#212b36',
};

const getThemePalette = (mode) => ({
  palette: {
    mode,
    ...(mode === MODE.light
      ? {
          // palette values for light mode
          primary: primary,
          secondary: secondary,
          background: {
            default: primary.main,
            paper: white[100],
          },
          text: {
            primary: dark[100],
            error: secondary.error,
            const: white[100],
          },
        }
      : {
          // palette values for dark mode
          primary: primary,
          secondary: secondary,
          background: {
            default: primary.dark,
            paper: dark[100],
          },
          text: {
            primary: white[100],
            error: secondary.error,
            const: white[100],
          },
        }),
  },
});

export { secondary };
export default getThemePalette;
