import axios from 'axios';

import headers from './HeaderApi.js';
import { clearLocaleStorage } from 'utility/Helper.js';

const API_URL = 'https://medicaidbot.ai:8649/api/';

const TOKEN_DICTIONARY = 'accessToken';

const tokenInstance = axios.create({
  baseURL: API_URL,
  headers: headers.apiHeaders,
});

const pureInstance = axios.create({
  baseURL: API_URL,
  headers: headers.apiHeaders,
});

pureInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

pureInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);

tokenInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(TOKEN_DICTIONARY);

    if (!accessToken) {
      // do something
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

tokenInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const Forbidden = error.response.status === 403;
    const Unauthorized = error.response.status === 401;
    const InternalError = error.response.status === 500;

    if (Forbidden || Unauthorized || InternalError) {
      clearLocaleStorage();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export { pureInstance, tokenInstance, TOKEN_DICTIONARY };
export default API_URL;
