const LOGIN_ENDPOINT = '/login';
const GET_USER_ENDPOINT = '/users';
const STATUS_ENDPOINT = '/subscriptions/status';

const LOGIN_REDUX = 'login';
const LOGIN_REQUEST_REDUX = 'login/post';
const GET_USER_REDUX = 'login/getUser';
const STATUS_REDUX = 'login/status';

export {
  LOGIN_ENDPOINT,
  STATUS_ENDPOINT,
  GET_USER_ENDPOINT,
  LOGIN_REQUEST_REDUX,
  LOGIN_REDUX,
  STATUS_REDUX,
  GET_USER_REDUX,
};
