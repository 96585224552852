import { Navigate } from 'react-router';
import React from 'react';

import { START_PATH } from 'router/path';

const RedirectPage = () => {
  return <Navigate to={START_PATH} replace />;
};

export default RedirectPage;
