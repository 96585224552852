import { createSlice } from '@reduxjs/toolkit';

import { ADMIN_REDUX } from './AdminPageConstants';
import getUsersAction, {
  adminSendAnswerAction,
  getAdminMessagesAction,
  getAdminQuestionAction,
  getAllUsersAction,
} from './AdminPageActions';

const initialState = {
  adminUsersFetching: false,
  adminAllUsersFetching: false,
  adminMessagesFetching: false,
  adminQuestionsFetching: false,
  adminSendingFetching: false,
};

const adminPageSlice = createSlice({
  name: ADMIN_REDUX,
  initialState,
  reducers: {
    setUsersFetching: (state, payload) => {
      state.chatsFetching = payload;
    },
  },
  extraReducers: {
    [getUsersAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.adminUsersFetching = true;
    },
    [getUsersAction.fulfilled.type]: (state) => {
      state.adminUsersFetching = false;
    },
    [getUsersAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.adminUsersFetching = false;
    },

    [getAllUsersAction.pending.type]: (state) => {
      state.adminAllUsersFetching = true;
    },
    [getAllUsersAction.fulfilled.type]: (state) => {
      state.adminAllUsersFetching = false;
    },
    [getAllUsersAction.rejected.type]: (state) => {
      state.adminAllUsersFetching = false;
    },

    [getAdminMessagesAction.pending.type]: (state) => {
      state.adminMessagesFetching = true;
    },
    [getAdminMessagesAction.fulfilled.type]: (state) => {
      state.adminMessagesFetching = false;
    },
    [getAdminMessagesAction.rejected.type]: (state) => {
      state.adminMessagesFetching = false;
    },

    [getAdminQuestionAction.pending.type]: (state) => {
      state.adminQuestionsFetching = true;
    },
    [getAdminQuestionAction.fulfilled.type]: (state) => {
      state.adminQuestionsFetching = false;
    },
    [getAdminQuestionAction.rejected.type]: (state) => {
      state.adminQuestionsFetching = false;
    },

    [adminSendAnswerAction.pending.type]: (state) => {
      state.adminSendingFetching = true;
    },
    [adminSendAnswerAction.fulfilled.type]: (state) => {
      state.adminSendingFetching = false;
    },
    [adminSendAnswerAction.rejected.type]: (state) => {
      state.adminSendingFetching = false;
    },
  },
});

export const { setUsersFetching } = adminPageSlice.actions;
export default adminPageSlice.reducer;
