import { Navigate } from 'react-router';
import React from 'react';

import { ADMIN_HOME_PATH, START_PATH, USER_HOME_PATH } from 'router/path';
import { ROLE_DICTIONARY } from 'core/Constants';

const RoleRedirectPage = () => {
  const role = localStorage.getItem('role');
  const isAdmin = role === ROLE_DICTIONARY.admin;

  if (!role) return <Navigate to={START_PATH} replace />;

  return isAdmin ? (
    <Navigate to={ADMIN_HOME_PATH} replace />
  ) : (
    <Navigate to={USER_HOME_PATH} replace />
  );
};

export default RoleRedirectPage;
