import TYPE_DICTIONARY, { get_request, post_request } from 'api/Requests';

import {
  GET_USER_PAYMENT_ENDPOINT,
  GET_USER_PAYMENT_REDUX,
  UNSUBSCRIBE_REDUX,
  UNSUBSCRIVE_ENDPOINT,
  USER_CHANGE_PASSWORD_ENDPOINT,
  USER_CHANGE_PASSWORD_REDUX,
  USER_CHANGE_PAYMENT_ENDPOINT,
  USER_CHANGE_PAYMENT_REDUX,
} from './UserSettingsConstants';

const userChangePasswordAction = post_request(
  USER_CHANGE_PASSWORD_REDUX,
  USER_CHANGE_PASSWORD_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const userChangePaymentAction = post_request(
  USER_CHANGE_PAYMENT_REDUX,
  USER_CHANGE_PAYMENT_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const getUserPaymentInfoAction = get_request(
  GET_USER_PAYMENT_REDUX,
  GET_USER_PAYMENT_ENDPOINT,
  TYPE_DICTIONARY.token,
);

const unsubscribeAction = post_request(
  UNSUBSCRIBE_REDUX,
  UNSUBSCRIVE_ENDPOINT,
  TYPE_DICTIONARY.token,
);

export { userChangePaymentAction, getUserPaymentInfoAction, unsubscribeAction };
export default userChangePasswordAction;
