const GET_ADMIN_STATISTIC_EDNPOINT = '/information/statistic';

const ADMIN_STATISTIC_REDUX = 'adminStatistic';
const GET_ADMIN_STATISTIC_REDUX = 'adminStatistic/information';

export {
  GET_ADMIN_STATISTIC_EDNPOINT,
  ADMIN_STATISTIC_REDUX,
  GET_ADMIN_STATISTIC_REDUX,
};
