const USER_CHANGE_PASSWORD_ENDPOINT = '/users/password/change';
const USER_CHANGE_PAYMENT_ENDPOINT = '/payment/stripe/update';
const GET_USER_PAYMENT_ENDPOINT = '/payment/info';
const UNSUBSCRIVE_ENDPOINT = '/subscriptions/cancel';

const USER_SETTINGS_REDUX = 'userSettings';
const USER_CHANGE_PASSWORD_REDUX = 'userSettings';
const USER_CHANGE_PAYMENT_REDUX = 'userSettings/paymentUpdate';
const GET_USER_PAYMENT_REDUX = 'userSettings/paymentInfo';
const UNSUBSCRIBE_REDUX = 'userSettings/unsubscribe';

export {
  USER_CHANGE_PASSWORD_ENDPOINT,
  USER_CHANGE_PAYMENT_ENDPOINT,
  GET_USER_PAYMENT_ENDPOINT,
  UNSUBSCRIVE_ENDPOINT,
  USER_SETTINGS_REDUX,
  USER_CHANGE_PASSWORD_REDUX,
  UNSUBSCRIBE_REDUX,
  USER_CHANGE_PAYMENT_REDUX,
  GET_USER_PAYMENT_REDUX,
};
